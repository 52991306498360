<template>
  <mp-box
    id="qon-announcement-banner"
    as="div"
    :bg-color="bannerUserStatus.status === 'trial' ? 'violet.400' : 'amber.100'"
    px="3"
    py="2"
  >
    <!-- :bg-color="warningTrialStatus.isShow ? 'violet.400' : 'amber.100'" -->
    <mp-flex justify-content="space-between">
      <mp-flex class="centered-item">
        <mp-icon
          name="warning-circular"
          :color="bannerUserStatus.status === 'trial' ? 'white' : 'black'"
          variant="fill"
          mr="3"
        />
        <!-- :color="warningTrialStatus.isShow ? 'white' : 'black'" -->
        <mp-box font-weight="semibold" ml="2">
          <!-- v-if="warningTrialStatus.isShow" -->
          <mp-text
            v-if="bannerUserStatus.status === 'trial'"
            as="span"
            color="white"
            >{{
              `Your Qontak Omnichannel trial access will expire in ${getExpiredTrial(
                billingInfo.valid_until
              )} days. To upgrade your access, please contact the company admin.`
            }}
          </mp-text>
          <!-- v-if="warningFreezeStatus.isShow" -->
          <mp-text
            v-if="bannerUserStatus.status === 'freeze'"
            as="span"
            color="black"
            >Your Qontak Omnichannel access is restricted. Please consult
            further with our support team.
          </mp-text>
          <mp-box
            v-if="!isAgent()"
            as="span"
            :color="bannerUserStatus.status === 'trial' ? 'white' : 'black'"
            text-decoration-line="underline"
            cursor="pointer"
            font-weight="regular"
            @click="$emit('redirect-to-customer-care')"
            >Contact support.</mp-box
          >
        </mp-box>
      </mp-flex>
      <mp-box
        v-show="!isAgent()"
        as="div"
        cursor="pointer"
        @click="$emit('on-close-banner-status', false)"
      >
        <mp-icon
          v-if="bannerUserStatus.status === 'trial'"
          name="close"
          color="white"
          variant="fill"
          mr="3"
          p="1"
        />
      </mp-box>
    </mp-flex>
  </mp-box>
</template>

<script>
export default {
  name: 'BannerUserStatus',
  props: {
    bannerUserStatus: {
      type: Object,
      default: () => ({}),
    },
    billingInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.onCheckBannerHeight()
  },
  methods: {
    getExpiredTrial(date) {
      const unixdate = this.$moment.unix(date).format('YYYY-MM-DD')
      const expiredDate = this.$moment(unixdate)
      const todaysDate = this.$moment()
      return expiredDate.diff(todaysDate, 'days')
    },
    onCheckBannerHeight() {
      const banner = document.querySelector('#qon-announcement-banner')
      banner &&
        this.$emit('set-banner-height', {
          type: 'user-status',
          value: banner.offsetHeight || 0,
        })
    },
  },
}
</script>
