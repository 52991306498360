<template>
  <mp-box
    id="qon-announcement-maintenance"
    as="div"
    bg-color="ice.100"
    px="3"
    py="2"
  >
    <mp-flex class="centered-grid-item">
      <mp-icon name="warning-triangle" color="black" variant="fill" mr="3" />
      <mp-text v-dompurify-html="bannerMaintenance.message" as="span" />
    </mp-flex>
  </mp-box>
</template>

<script>
export default {
  name: 'BannerMaintenance',
  props: {
    bannerMaintenance: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.onCheckBannerHeight()
  },
  methods: {
    onCheckBannerHeight() {
      const banner = document.querySelector('#qon-announcement-maintenance')
      banner &&
        this.$emit('set-banner-height', {
          type: 'maintenance',
          value: banner.offsetHeight || 0,
        })
    },
  },
}
</script>
