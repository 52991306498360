<template>
  <mp-form-control control-id="phone-number" margin-bottom="4" is-required>
    <ValidationProvider
      v-slot="{ errors }"
      name="phone number"
      rules="required|min:8|max:15"
    >
      <mp-form-label> Phone number </mp-form-label>
      <mp-input-group>
        <mp-input
          v-model="activePhoneNumber.phone"
          type="number"
          name="pic_phone_number"
          @keyup="sendPhoneNumber"
        />
        <mp-input-left-addon>
          <mp-flex>
            <mp-flex
              @mouseover="isoutsidepopover = false"
              @mouseleave="isoutsidepopover = true"
            >
              <mp-popover
                :is-open="ispopoveropen"
                initial-focus-ref="#filter-search"
                is-manual
              >
                <mp-popover-trigger>
                  <mp-flex
                    as="button"
                    padding-x="2"
                    gap="2"
                    align-items="center"
                    @click.native="handleOpenPopover"
                  >
                    <mp-text font-weight="bold">{{
                      countryActive.flag
                    }}</mp-text>
                    <mp-icon name="chevrons-down" size="sm" />
                  </mp-flex>
                </mp-popover-trigger>
                <mp-popover-content
                  width="420px"
                  background-color="white"
                  border-radius="md"
                  border-width="1px"
                  border-color="gray.400"
                  box-shadow="lg"
                  padding-y="2"
                  margin-left="-4px !important"
                  max-height="300px"
                  overflow-y="auto"
                  @click.native="handleForceInputFocus"
                >
                  <mp-box margin-top="2" margin-bottom="2" padding-x="3">
                    <mp-input-group>
                      <mp-input-left-addon :with-background="false">
                        <mp-icon name="search" size="sm" />
                      </mp-input-left-addon>
                      <mp-input
                        id="filter-search"
                        v-model="searchCountryDial"
                        name="search_country_dial"
                        placeholder="Search..."
                        @blur="handleClickOutside"
                        @focus="handleFocus"
                        @keyup="onSearchEnterCountry()"
                      />
                    </mp-input-group>
                  </mp-box>
                  <mp-box>
                    <mp-popover-list padding="0">
                      <mp-popover-list-item
                        v-for="list in countrydata"
                        :key="list.name"
                        :is-active="list.name === countryActive.name"
                        @click="selectDialCountry(list)"
                      >
                        <mp-flex>
                          {{ list.flag }}
                          <mp-text margin-left="2">
                            {{ list.name }}
                          </mp-text>
                        </mp-flex>
                        <mp-text color="gray.600" margin-left="5">
                          {{ list.dial_code }}
                        </mp-text>
                      </mp-popover-list-item>
                      <mp-popover-list-item v-if="isloading">
                        <mp-flex align-items="center">
                          <mp-spinner size="sm" margin-right="2" />
                          Loading...
                        </mp-flex>
                      </mp-popover-list-item>

                      <div id="observer" />
                    </mp-popover-list>
                  </mp-box>
                </mp-popover-content>
              </mp-popover>
            </mp-flex>

            <mp-box background-color="white" padding-y="6px" padding-left="6px">
              <mp-text color="gray.600">{{ countryActive.dial_code }}</mp-text>
            </mp-box>
          </mp-flex>
        </mp-input-left-addon>
      </mp-input-group>
      <mp-text v-if="errors && errors.length > 0" color="red.400">
        {{ 'Format is incorrect, please recheck your phone number' }}
      </mp-text>
    </ValidationProvider>
  </mp-form-control>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import countryCode from '../../utils/countryCode'

export default {
  name: 'PhoneNumberFieldWithCountryCode',
  components: {
    ValidationProvider,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone_number: '',
      activePhoneNumber: {
        dial_code: '+62',
        phone: '',
      },
      fetchdata: [],
      countrydata: [],
      countryActive: {
        name: 'Indonesia',
        flag: '🇮🇩',
        dial_code: '+62',
      },
      countryTempData: [],
      isloading: false,
      process: false,
      observer: null,
      currentopen: false,
      ispopoveropen: false,
      ispopoverfocused: false,
      isoutsidepopover: true,
      searchCountryDial: '',
    }
  },
  beforeMount() {
    this.fetchdata = countryCode
    if (this.phoneNumber) {
      this.validatePhoneNumberCountry(this.phoneNumber)
    }
  },
  mounted() {
    this.loadNextData()
  },
  methods: {
    onSearchEnterCountry() {
      if (!this.searchCountryDial) {
        this.countryTempData = []
        setTimeout(() => {
          this.countrydata = this.fetchdata
        }, 1000)
        return null
      }
      const filterItems = (needle) => {
        const query = needle.toLowerCase()
        return this.fetchdata.filter((item) =>
          item.name.toLowerCase().includes(query)
        )
      }
      this.countryTempData = filterItems(this.searchCountryDial)

      setTimeout(() => {
        this.countrydata = this.countryTempData
      }, 1000)
    },
    validatePhoneNumberCountry(data) {
      const code = '+' + data
      const validData = []
      let split = 0

      const threeDigit = this.fetchdata.filter(function (el) {
        return el.dial_code === code.substring(0, 4)
      })
      const twoDigit = this.fetchdata.filter(function (el) {
        return el.dial_code === code.substring(0, 3)
      })
      const oneDigit = this.fetchdata.filter(function (el) {
        return el.dial_code === code.substring(0, 2)
      })

      if (threeDigit.length > 0) {
        validData.push(threeDigit[0])
        split = 4
      }
      if (twoDigit.length > 0) {
        validData.push(twoDigit[0])
        split = 3
      }
      if (oneDigit.length > 0) {
        validData.push(oneDigit[0])
        split = 2
      }

      if (validData.length > 0) {
        this.countryActive = {
          name: validData[0]?.name,
          flag: validData[0]?.flag,
          dial_code: validData[0]?.dial_code,
        }
        this.activePhoneNumber.dial_code = code.substring(0, split)
        this.activePhoneNumber.phone = code.substring(split, code.length)
        this.sendPhoneNumber()
      }
    },
    sendPhoneNumber() {
      this.$emit(
        'number-phone',
        this.activePhoneNumber.dial_code + this.activePhoneNumber.phone
      )
    },
    loadNextData() {
      setTimeout(() => {
        const newdata = this.fetchdata
        this.countrydata.push(...newdata)
      }, 500)
    },

    // popover
    handleFocus() {
      this.ispopoverfocused = true
      if (!this.ispopoveropen) this.handleOpenPopover()
      if (this.ispopoveropen) this.handleForceInputFocus()
    },
    handleForceInputFocus() {
      if (this.ispopoverfocused) {
        const input = document.getElementById('filter-search')
        input.focus()
      }
    },
    handleOpenPopover() {
      this.ispopoveropen = true
    },
    handleClosePopover() {
      this.ispopoverfocused = false
      this.ispopoveropen = false
    },
    handleClickOutside() {
      if (this.isoutsidepopover) {
        this.handleClosePopover()
        this.isoutsidepopover = false
      }
    },
    handleOpen() {
      this.currentopen = true
    },
    handleClose() {
      this.currentopen = false
    },
    selectDialCountry(e) {
      this.countryActive = {
        name: e.name,
        flag: e.flag,
        dial_code: e.dial_code,
      }
      this.activePhoneNumber.dial_code = e.dial_code
      this.sendPhoneNumber()
      this.handleClosePopover()
    },
  },
}
</script>
