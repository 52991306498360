<template>
  <mp-box
    id="sticky-toast"
    position="absolute"
    top="120"
    left="0"
    right="0"
    z-index="toast"
  >
    <mp-flex
      width="689px"
      margin="auto"
      py="2"
      px="3"
      gap="2"
      border-width="1px"
      :border-color="color"
      border-radius="md"
      box-shadow="md"
      background="white"
    >
      <mp-icon
        :name="stickyToast.iconName"
        :variant="stickyToast.iconVariant"
        :color="color"
        size="md"
        mt="1"
      />
      <mp-text
        v-dompurify-html="stickyToast.message"
        width="full"
        as="span"
      ></mp-text>
      <mp-button-icon name="close" @click="handleClose" />
    </mp-flex>
  </mp-box>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StickyToast',
  data() {
    return {
      timeout: null,
    }
  },
  computed: {
    ...mapState('layouts', ['stickyToast']),
    color() {
      if (this.stickyToast.variant === 'error') {
        return 'red.400'
      }
      if (this.stickyToast.variant === 'success') {
        return 'green.400'
      }
      return 'blue.400'
    },
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.handleClose()
    }, this.stickyToast.duration)
  },
  destroyed() {
    clearTimeout(this.stickyToast.timeout)
  },
  methods: {
    handleClose() {
      this.$store.commit('layouts/UPDATE_STICKY_TOAST', { isShow: false })
    },
  },
}
</script>
